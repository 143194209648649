<template>
    <div class="m-2" :style="`direction:`+lang.dir">
        <b-sidebar backdrop id="popViewCard" width="99%" aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" right :title="lang.view_card" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:150px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class=" btn-sm text-center">
                        <span>{{lang.view_card}}</span>
                    </div>
                    <div @click="hide" id="closecardview" style="text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer;width:100px;" class="btn-danger btn-sm">
                        <span>{{lang.close}}</span>
                    </div>
                </div>
                <v-divider></v-divider>
                <div :style="`direction:`+lang.dir" class="m-2">
                    <v-col cols="12" v-if="showButtons">
                        <div class="backBlack" style="padding:0px;width:100%;text-align:center;">
                            <b-button style="font-size:1.1rem;border:2px solid #fff !important;background:blue !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success m-2" @click="showAttached()" v-b-toggle.add_gallery>{{lang.attachement}}</b-button> 
                            <b-button v-if="card.paid != card.ftotal && card.status != 2 && card.status != 4" style="font-size:1.1rem;border:2px solid #fff !important;background:red !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success m-2" @click="invpayment()" v-b-toggle.add_addpayment>{{lang.make_payment}}</b-button> 
                            <b-button v-if="card.invoiceid == 0" class="mytab1 btn btn-sm btn-default m-2" style="border:2px solid #fff !important;width:120px;background:lightgreen !important;font-size:1.1rem;color:#000;" @click="addInvoice()" v-b-toggle.add_invoice>{{lang.create_invoice}}</b-button> 
                            <b-button class="mytab1 btn btn-sm btn-default m-2" style="width:130px;background:darkslateblue !important;color:#fff !important;font-size:1.1rem;border:2px solid #fff !important" @click="printPDF(cardid,3)">{{lang.print_card}} </b-button> 
                            <b-button v-if="card.invoiceid != 0" class="mytab1 btn btn-sm btn-default m-2" style="border:2px solid #fff !important;width:130px;background:darkgreen !important;font-size:1.1rem;color:#fff;" @click="printPDF(card.invoiceid,1)">{{lang.view_pdf}}</b-button> 
                            <b-button v-if="card.sined != ''" class="mytab1 btn btn-sm btn-default m-2" style="border:2px solid #fff !important;width:130px;background:darkmagenta !important;font-size:1.1rem;color:#fff;" @click="$router.push({path: '/signcard/'+card.id})">{{lang.customer_sign}}</b-button> 
                            <!-- <b-button v-if="card._status_ != 3 && card.paid != card.ftotal" class="mytab1 btn btn-sm btn-default m-2" style="border:none !important;width:120px;background:#FFF !important;font-size:1.1rem;color:#000;" @click="printInvo(d)">{{lang.close_card}}</b-button>  -->
                            <img :src="`../whatsappicon.png`" style="border:2px solid #fff !important;width:120px;margin-inline-start: 10px;height: 38px;border-radius: 5px;cursor:pointer" @click="openWhatsAPP()" />
                        </div>
                    </v-col>
                </div>
                <v-row :style="`direction:`+lang.dir" class="m-2">
                    <v-col cols="12" md="6" sm="12">
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack" style="width:20%;">{{ lang.cardid }}</th>
                                    <th class="text-center backBlack" style="width:35%;">{{ lang.received_date }}</th>
                                    <th class="text-center backBlack" style="width:35%;">{{ lang.delivery_date }}</th>
                                    <th class="text-center backBlack" style="width:10%;">{{ lang.status }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-center">{{ card.card_id }}</td>
                                    <td class="text-center" v-if="card.received_date">{{ card.received_date.substring(0,10) }}</td>
                                    <td class="text-center" v-else> - </td>
                                    <td class="text-center" v-if="card.delivery_date">{{ card.delivery_date.substring(0,10) }}</td>
                                    <td class="text-center" v-else> - </td>
                                    <td class="text-center" :style="`background:${card._statusbg} !important;color:${card._statuscolor} !important`">{{ card['_status_'+lang.langname] }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                        <v-simple-table>
                            <thead>
                                <tr v-if="card.customer_type == 1">
                                    <th style="width:80px;">{{ lang.customer_type }}</th>
                                    <td :style="`background:${card._customer_type_bgcolor} !important;color:${card._customer_type_color} !important;`">{{ card['_customer_type_'+lang.langname] }}</td>
                                    <th style="width:80px;">{{ lang.full_name }}</th>
                                    <td>{{ card.customer }}</td>
                                    <th style="width:80px;">{{ lang.mobile }}</th>
                                    <td>{{ card.mobile }}</td>
                                </tr>
                                <tr v-if="card.customer_type == 2">
                                    <th style="width:80px;">{{ lang.customer_type }}</th>
                                    <td :style="`background:${card._customer_type_bgcolor} !important;color:${card._customer_type_color} !important;`">{{ card['_customer_type_'+lang.langname] }}</td>
                                    <th style="width:80px;">{{ lang.company_name }}</th>
                                    <td>{{ card.customer }}</td>
                                </tr>
                                <tr v-if="card.customer_type == 3">
                                    <th style="width:80px;">{{ lang.customer_type }}</th>
                                    <td :style="`background:${card._customer_type_bgcolor} !important;color:${card._customer_type_color} !important;`">{{ card['_customer_type_'+lang.langname] }}</td>
                                    <th style="width:80px;">{{ lang.company_name }}</th>
                                    <td>{{ card.customer }}</td>
                                    <th style="width:80px;">{{ lang.mobile }}</th>
                                    <td>{{ card.mobile }}</td>
                                </tr>
                            </thead>
                        </v-simple-table>
                        <v-simple-table>
                            <thead>
                                <tr v-if="card.customer_type == 3">
                                    <th style="width:80px;">{{ lang.branch_name }}</th>
                                    <td>{{ card.client.branch_name }}</td>
                                    <th style="width:80px;">{{ lang.order_number }}</th>
                                    <td>{{ card.order_number }}</td>
                                    <th style="width:80px;">{{ lang.request_number }}</th>
                                    <td>{{ card.request_number }}</td>
                                </tr>
                            </thead>
                        </v-simple-table>
                        <v-simple-table>
                            <thead>
                                <tr v-if="card.customer_type == 3">
                                    <th style="width:80px;">{{ lang.po_number }}</th>
                                    <td>{{ card.po_number }}</td>
                                    <th style="width:80px;">{{ lang.po_date }}</th>
                                    <td>{{ card.po_date }}</td>
                                </tr>
                            </thead>
                        </v-simple-table>
                        <v-simple-table>
                            <thead>
                                <tr v-if="card.customer_type == 2">
                                    <th style="width:80px;">{{ lang.mobile }}</th>
                                    <td>{{ card.mobile }}</td>
                                    <th style="width:80px;">{{ lang.company_vatid }}</th>
                                    <td>{{ card.client.company_vatid }}</td>
                                    <th style="width:80px;">{{ lang.crt_number }}</th>
                                    <td>{{ card.client.crt_number }}</td>
                                </tr>
                            </thead>
                        </v-simple-table>
                        <v-simple-table>
                            <thead>
                                <tr v-if="card.customer_type == 2">
                                    <th style="width:80px;">{{ lang.address }}</th>
                                    <td>{{ address }}</td>
                                </tr>
                            </thead>
                        </v-simple-table>
                        <br />
                        <h6 style="background:lightblue;padding:3px;margin-bottom:10px;margin-top:10px;text-align:center;">{{ lang.car_info }}</h6>
                        <v-simple-table v-for="(item,index) in card._cars" :key="index">
                            <thead>
                                <tr>
                                    <th class="text-center backBlack" style="width:85px;">{{ lang.plate_number }}</th>
                                    <td>{{ item.plate_number }}</td>
                                    <th class="text-center backBlack" style="width:85px;">{{ lang.car_model }}</th>
                                    <td>{{ item.model }}</td>
                                    <th class="text-center backBlack" style="width:85px;">{{ lang.car_year }}</th>
                                    <td>{{ item.manufacturing_year }}</td>
                                </tr>
                                <tr>
                                    <th class="text-center backBlack" style="width:85px;">{{ lang.car_color }}</th>
                                    <td>{{ item.color }}</td>
                                    <th class="text-center backBlack" style="width:85px;">{{ lang.vin_numebr }}</th>
                                    <td colspan="3">{{ item.vin }}</td>
                                </tr>
                                <tr><td style="background:none;border:none !important;"></td></tr>
                            </thead>
                        </v-simple-table>
                        <h6 style="background:lightblue;padding:3px;margin-bottom:10px;margin-top:10px;text-align:center;">{{ lang.terms_and_conditions }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="backBlack">{{ lang.terms }}</th>
                                    <!-- <th class="backBlack" style="width:80px;">{{lang.action}}</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in terms" :key="index">
                                    <td>{{ item.terms }}</td>
                                    <!-- <td></td> -->
                                </tr>
                            </tbody>
                            <!-- <tfoot>
                                <tr>
                                    <th>
                                        <b-form-input class="inborder" v-model="iterms" />
                                    </th>
                                    <th>
                                        <v-btn @click="addTerms()" style="background:green;color:#fff;padding:3px !important;margin-top:3px;">{{ lang.add }}</v-btn>
                                    </th>
                                </tr>
                            </tfoot> -->
                        </v-simple-table>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack">{{ lang.total }}</th>
                                    <th class="text-center backBlack">{{ lang.vat }}</th>
                                    <th class="text-center backBlack">{{ lang.ftotal }}</th>
                                    <th class="text-center backBlack">{{ lang.paid }}</th>
                                    <th class="text-center backBlack">{{ lang.remain }}</th>
                                    <th class="text-center backBlack">{{ lang.expenses }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th class="text-center">{{ $convertToComa(card.total,$store.state.licenseType.moneyComma) }}</th>
                                    <th class="text-center">{{ $convertToComa(card.vat,$store.state.licenseType.moneyComma) }}</th>
                                    <th class="text-center">{{ $convertToComa(card.ftotal,$store.state.licenseType.moneyComma) }}</th>
                                    <th class="text-center">{{ $convertToComa(card.paid,$store.state.licenseType.moneyComma) }}</th>
                                    <th class="text-center">{{ $convertToComa(remain,$store.state.licenseType.moneyComma) }}</th>
                                    <th class="text-center" style="background:red !important;color:#fff !important;">{{ card.expenses }}</th>
                                </tr>
                            </tbody>
                        </v-simple-table>
                        <br />
                        <h6 style="background:lightblue;padding:3px;margin-bottom:10px;margin-top:10px;text-align:center;">{{ lang.payments }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack">{{ lang.payment_date }}</th>
                                    <th class="text-center backBlack">{{ lang.payment_amount }}</th>
                                    <th class="text-center backBlack">{{ lang.payment_method }}</th>
                                    <th class="text-center backBlack" style="width:75px;">{{ lang.print }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr  v-for="(item,index) in card.payments" :key="index">
                                    <td class="text-center">{{ item.receipt_date }}</td>
                                    <td class="text-center">{{ $convertToComa(item.ftotal,$store.state.licenseType.moneyComma) }}</td>
                                    <td class="text-center">{{ item['_paytype_'+lang.langname] }}</td>
                                    <td class="text-center"  @click="printPDF(item.id,2)" style="border:2px solid #fff;background:green;color:#fff !important;cursor:pointer">{{ lang.print }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                        <h6 style="background:lightblue;padding:3px;margin-bottom:10px;margin-top:10px;text-align:center;">{{ lang.description }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack">{{ lang.plate_number }}</th>
                                    <th class="text-center backBlack">{{ lang.worker_name }}</th>
                                    <!-- <th class="text-center backBlack">{{ lang.item_code }}</th> -->
                                    <th class="text-center backBlack">{{ lang.description }}</th>
                                    <th class="text-center backBlack">{{ lang.total }}</th>
                                    <th class="text-center backBlack">{{ lang.status }}</th>
                                    <th class="text-center backBlack" colspan="2" v-if="card.invoiceid == 0" style="width:150px;">{{ lang.action }}</th>
                                    <th class="text-center backBlack" v-else style="width:80px;">{{ lang.action }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr  v-for="(item,index) in card.rows" :key="index">
                                    <td class="text-center">{{ item.plate_number }}</td>
                                    <td class="text-center">{{ item.emp_name }}</td>
                                    <!-- <td class="text-center">{{ item.itemcode }}</td> -->
                                    <td class="text-center" :title="lang.item_code + `: ` + item.itemcode">{{ item.item_name }}</td>
                                    <td class="text-center">{{ $convertToComa(item.ftotal,$store.state.licenseType.moneyComma) }}</td>
                                    <td class="text-center" :style="`background:${item._rowStatus_bg} !important;color:${item._rowStatus_color} !important`">{{ item['_rowStatus_'+lang.langname] }}</td>
                                    <td class="text-center" v-if="card.invoiceid == 0" @click="deleteRowx(item)" style="border:2px solid #fff !important;background:red;color:#fff !important;cursor:pointer">{{ lang.delete }}</td>
                                    <td class="text-center" v-if="item.status != 2" @click="finishRowx(item,0)" style="border:2px solid #fff !important;background:green;color:#fff !important;cursor:pointer">{{ lang.complete }}</td>
                                    <td class="text-center" v-else @click="finishRowx(item,1)" style="border:2px solid #fff !important;background:red;color:#fff !important;cursor:pointer">ارجاع</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                        <h6 style="background:lightblue;padding:3px;margin-bottom:10px;margin-top:10px;text-align:center;">{{ lang.expenses }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack">{{ lang.plate_number }}</th>
                                    <th class="text-center backBlack">{{ lang.worker_name }}</th>
                                    <th class="text-center backBlack">{{ lang.description }}</th>
                                    <th class="text-center backBlack">{{ lang.qtty }}</th>
                                    <th class="text-center backBlack">{{ lang.total }}</th>
                                    <th class="text-center backBlack" style="width:75px;" v-if="card.invoiceid == 0">{{ lang.action }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr  v-for="(item,index) in card.expense" :key="index">
                                    <td class="text-center">{{ item.plate_number }}</td>
                                    <td class="text-center">{{ item.emp_name }}</td>
                                    <!-- <td class="text-center">{{ item.itemcode }}</td> -->
                                    <td class="text-center" :title="lang.item_code + `: ` + item.itemcode">{{ item.item_name }}</td>
                                    <td class="text-center">{{ item.qty }}</td>
                                    <td class="text-center">{{ $convertToComa(item.ftotal,$store.state.licenseType.moneyComma) }}</td>
                                    <td class="text-center"  v-if="card.invoiceid == 0" @click="deleteRowx(item)" style="border:2px solid #fff;background:red;color:#fff !important;cursor:pointer">{{ lang.delete }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
                <v-row :style="`direction:`+lang.dir" class="m-2" v-if="card.invoiceid == 0">
                    <h6 style="background:lightblue;padding:3px;margin-bottom:10px;margin-top:10px;text-align:center;">{{ lang.add_new_work_expenses }}</h6>
                    <v-simple-table striped hover style="width:100%;">
                        <thead>
                            <tr>
                                <th class="text-center backBlack" style="width:140px;">{{lang.vat_type}}</th>
                                <th class="text-center backBlack" style="width:140px;">{{lang.row_type}}</th>
                                <th class="text-center backBlack" style="width:140px;">{{lang.worker_name}}</th>
                                <th class="text-center backBlack" style="width:120px;">{{lang.chose_car}}</th>
                                <th class="backBlack" style="width:100px;" v-if="$store.state.licenseType.showItemCode">{{lang.item_code}}</th>
                                <th class="text-center backBlack">{{lang.description}}</th>
                                <th class="text-center backBlack" style="width:50px">{{lang.qtty}}</th>
                                <th class="text-center backBlack" style="width:100px">{{lang.item_price}}</th>
                                <th class="text-center backGreen" style="width:100px">{{lang.total}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <b-input-group>
                                    <b-form-select
                                        class="selborder"
                                        style="background:red !important"
                                        :options="vattypes"
                                        v-model="newrow.vattype">
                                    </b-form-select>
                                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                        <i class="fas fa-arrow-down"></i>
                                        </b-input-group-append>
                                    </b-input-group>
                                </td>
                                <td>
                                    <b-input-group>
                                    <b-form-select
                                        class="selborder"
                                        style="background:red !important"
                                        :options="rowType"
                                        v-model="newrow.type">
                                    </b-form-select>
                                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                        <i class="fas fa-arrow-down"></i>
                                        </b-input-group-append>
                                    </b-input-group>
                                </td>
                                <td>
                                    <b-input-group>
                                    <b-form-select
                                        class="selborder"
                                        style="background:red !important"
                                        :options="workers"
                                        v-model="newrow.workers_name">
                                    </b-form-select>
                                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                        <i class="fas fa-arrow-down"></i>
                                        </b-input-group-append>
                                    </b-input-group>
                                </td>
                                <td>
                                    <b-input-group>
                                    <b-form-select
                                        class="selborder"
                                        style="background:red !important"
                                        :options="currentCars"
                                        v-model="newrow.plate_number">
                                    </b-form-select>
                                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                        <i class="fas fa-arrow-down"></i>
                                        </b-input-group-append>
                                    </b-input-group>
                                </td>
                                <td v-if="$store.state.licenseType.showItemCode">
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.itemcode"
                                        @change="getProd()"
                                        >
                                    </b-form-input>
                                </td>
                                <td>
                                    <v-combobox
                                        v-model="newrow.item_name"
                                        :items="products"
                                        small-chips
                                        @change="getItemCode()"
                                    ></v-combobox>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        style="width:50px;"
                                        v-model="newrow.qty"
                                        @change="calcKit()"
                                        id="newitemqty"
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        style="width:75px;"
                                        v-model="newrow.item_price"
                                        @change="calcKit()"
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.total"
                                        @change="calcKit()"
                                        readonly
                                    ></b-form-input>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="9" class="text-end" style="border-left:none !important;border-right:none !important;border-bottom:none !important">
                                    <v-btn id="addNewRow" @click="addNewRow()" style="background:green;color:#fff;padding:3px !important;margin-top:3px;">{{ lang.add }}</v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-row>
                <v-row :style="`direction:`+lang.dir" class="m-2">
                    <h6 style="background:lightblue;padding:3px;margin-bottom:10px;margin-top:10px;text-align:center;">{{ lang.car_status_receive }}</h6>
                    <v-col cols="12" md="6" sm="12" v-for="(image,index) in carStatus.images" :key="index">
                        <div style="border:1px solid #000" class="backBlack">{{ image.plate }}</div>
                        <v-row>
                            <v-col cols="12" md="4" sm="12">
                                <div style="border:1px solid #000"><img :src="image.image" style="width:100%"></div>
                            </v-col>
                            <v-col cols="12" md="8" sm="12">
                                <v-simple-table v-for="(items,indo) in carStatus.status" :key="indo">
                                    <tr v-if="items.plate == image.plate">
                                        <td >{{ items.text }}</td>
                                    </tr>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                        
                    </v-col>
                </v-row>
            </template>
        </b-sidebar>
        <vue-snotify></vue-snotify>
        <AddCardPayment ref="cardPayments" />
        <addInvoice ref="addInvoice" />
        <viewCardGallery ref="viewCardGall" />
    </div>
</template>

<script>
import axios from 'axios'

import {SnotifyPosition} from 'vue-snotify';
import AddCardPayment from '@/components/addCardPayment.vue'
import addInvoice from '@/components/addInvoice.vue'
import viewCardGallery from '@/components/viewCardGallery.vue'

export default {
    components:{AddCardPayment,addInvoice,viewCardGallery},
    data() {
        return {
            terms: [],
            iterms: '',
            cardid: 0,
            card: {},
            showButtons: true,
            newrow:{
                vattype: 3,
                empname: '',
                itemcode: '',
                item_name: '',
                qty: 1,
                item_price: '',
                total: '',
                vat: '',
                ftotal: '',
                plate_number: '',
                workers_name: '',
                type: 1,
                itemid: 0
            },
            workers:[],
            currentCars:[],
            vattypes:[],
            rowType:[],
            products:[],
            allProducts: []
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        address: function(){
            let t = "";
            if(this.card.client.address != '') {
                t = this.card.client.address;
            }else{
                t = this.card.client.building_no + " " + this.card.client.street_name + " ";
                t = t + this.card.client.branch_number + " - " + this.card.client.zipcode + " ";
                t = t + this.card.client.district + " - " + this.card.client.city;
            }
            return t;
        },
        remain: function(){
            let t = this.card.ftotal;
            if(this.card.paid != 0 && this.card.paid != '') {
                t = parseFloat(t) - parseFloat(this.card.paid)
            }
            return t;
        },
        carStatus: function(){
            let t = {images: [],status: []};
            if(typeof this.card._cars === 'undefined') return t;
            for(let i=0;i<this.card._cars.length;i++){
                let hasimg = false;
                for(let j=0;j<this.card.images.length;j++){
                    if(this.card._cars[i].plate_number == this.card.images[j].plate_number){
                        hasimg = true;
                        t.images.push({
                            image: this.card.images[j].bvalue,
                            plate: this.card.images[j].plate_number,
                        })
                    }
                }
                for(let j=0;j<this.card.status.length;j++){
                    
                    if(this.card._cars[i].plate_number == this.card.status[j].plate_number){
                        t.status.push({
                            text: this.card.status[j].bvalue,
                            plate: this.card.status[j].plate_number,
                        })
                    }
                }
                if(!hasimg){
                    t.images.push({
                        image: './img/car-background.png',
                        plate: this.card._cars[i].plate_number
                    })
                }
            }
            return t;
        }
    },
    created() {
        //setInterval(() => {this.calckInv();},10000);
        this.getUsers();
        this.getStatic();
        this.getProducts();
    },
    methods: {
        signMe(){
            //
        },
        async addTerms(){
            const post = new FormData();
            post.append("type","addCardTerms");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[id]',this.card.id);
            post.append('data[terms]',this.iterms);
            const response = await axios.post(this.$store.state.SAMCOTEC.r_path, post)
            if(response && response.data){
                this.getCard(this.card.id);
                this.iterms = '';
            }
        },
        addInvoice(){
            this.getCard(this.card.id);
            this.$refs.addInvoice.cars = this.card._cars;
            if(this.card.customer_type == 2 || this.card.customer_type == 3){
                this.$refs.addInvoice.company = this.card.company;
            }
            
            this.$refs.addInvoice.customer = this.card.client;
            this.$refs.addInvoice.cctype = this.card.customer_type;
            
            this.$refs.addInvoice.tbrows = this.card.rows;
            this.$refs.addInvoice.cardid = this.card.id;
            this.$refs.addInvoice.calcAll()
            this.$refs.addInvoice.getName(0)
            this.$refs.addInvoice.changeCusType(parseFloat(this.card.customer_type) - 1 );
            console.log(this.$refs.addInvoice.company);
        },
        finishRowx(item,id){
            let message = "هل أنت متاكد انك قبمت بانهاء المهمة";
            if(id == 1){
                message = "هل انت متاكد بان العمل بهذا الصنف غير منتهي؟";
            }
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.agree, 
                            action: (toast) => {
                                this.finishRow(item,id);
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                        {
                            text: this.lang.cancel, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
        },
        deleteRowx(item){
            let message = "هل أنت متأكد بانك تريد حذف هذا العنصر";
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.agree, 
                            action: (toast) => {
                                this.deleteRow(item);
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                        {
                            text: this.lang.cancel, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
        },
        async deleteRow(item){
            if(parseFloat(item.ftotal) >= parseFloat(this.remain) && item.type == 1){
                let message = "لا يمكن حذف مبلغ يتجاوز قيمة الدفعة المتبقية";
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append("type","deleteItemFromCard");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[id]',item.id);
            const response = await axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ) 
            if(response && response.data && response.data.results && response.data.results.data){
                await this.getProducts();
                await this.getCard(this.card.id);
                let message = "تم الحذف بنجاح";
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
        },
        async finishRow(item,id){
            
            const post = new FormData();
            post.append("type","finishItemFromCard");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[id]',item.id);
            post.append('data[doit]',id);
            const response = await axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ) 
            if(response && response.data && response.data.results && response.data.results.data){
                await this.getProducts();
                await this.getCard(this.card.id);
                let message = "تم الانتهاء بنجاح";
                if(id == 1){
                    message = "تم اعادة العمل بنجاح";
                }
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
        },
        showAttached(){
            this.$refs.viewCardGall.card.cardid = this.card.id;
            this.$refs.viewCardGall.getImages();
        },
        invpayment(){
            this.$refs.cardPayments.id = this.card.id;
            this.$refs.cardPayments.cardid = this.card.card_id;
            this.$refs.cardPayments.cAmount = this.remain;
            this.$refs.cardPayments.received_total = this.remain;
        },
        async addNewRow(){
            document.getElementById('addNewRow').style.display = "none";
            const post = new FormData();
            post.append("type","addNewRowToCard");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            Object.keys(this.newrow).forEach(key => {
                if(key == "item_name"){
                    if(typeof this.newrow.item_name === 'object'){
                        Object.keys(this.newrow.item_name).forEach(key1 => {
                            post.append("data[item_name]["+key1+"]",this.newrow.item_name[key1]);
                        });
                    }else{
                        post.append("data[item_name][text]",this.newrow.item_name);
                    }
                }else{
                    post.append("data["+key+"]",this.newrow[key]);
                    
                }
            })
            post.append("data[cardid]",this.card.id)
            const response = await axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            )
            document.getElementById('addNewRow').style.display = "inherit";
            if(response && response.data && response.data.results && response.data.results.data){

                await this.getProducts();
                this.resetNewrow()
                await this.getCard(this.card.id);
            }
            
        },
        calcKit(){
            if(this.newrow.qty != '' && this.newrow.item_price != ''){
                const q = parseFloat(this.newrow.qty) * parseFloat(this.newrow.item_price);
                this.newrow.total = this.$RoundNums(q);
            }else{
                this.newrow.total = 0;
            }
        },
        getItemCode(){
            for(let i=0;i<this.products.length;i++){
                if(this.products[i].value == this.newrow.item_name.value){
                    this.newrow.itemcode = this.products[i].itemcode
                    this.newrow.itemid = this.products[i].id
                    this.newrow.item_price = this.products[i].item_price
                    this.calcKit();
                    return false;
                }
            }
        },
        getProd(){

            if(this.newrow.itemcode == ''){
                this.newrow.item_name = "";
            }
            for(let i=0;i<this.products.length;i++){
                if(this.products[i].itemcode == this.newrow.itemcode){
                    this.newrow.item_name = this.products[i].text
                    this.newrow.itemid = this.products[i].id
                    this.newrow.item_price = this.products[i].item_price
                    this.calcKit();
                    return false;
                }
            }
        },
        async getProducts(){
            const post = new FormData();
            post.append("type","getProducts");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[item_number]',this.newrow.itemcode);
            const response = await axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            )
            
            if(response && response.data && response.data.results && response.data.results.data){
                const res = response.data.results.data;
                this.allProducts = res;
                for(let i=0;i<res.length;i++){
                    this.products.push({
                        text: res[i]['name'+this.lang.langname],
                        value: res[i]['name'+this.lang.langname],
                        id: res[i].id,
                        itemcode: res[i].itemcode,
                        item_price: res[i].price
                    });
                }
            }
         
        },
        getUsers(){
            const post = new FormData();
            post.append("type","getWorkers");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[item_number]',this.newrow.itemcode);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                this.workers = response.data.results.data
                this.newrow.workers_name = response.data.results.data[0].value
            });
        },
        resetNewrow(){
            this.newrow = {
                vattype: 3,
                empname: '',
                itemcode: '',
                item_name: '',
                qty: 1,
                item_price: '',
                total: '',
                vat: '',
                ftotal: '',
                plate_number: this.newrow.plate_number,
                workers_name: this.newrow.workers_name,
                type: 1,
                itemid: 0
            }
        },
        async getCard(id) {
            const post = new FormData();
            post.append("type","getCurretnCard");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append("data[id]",id)
            const response = await axios.post(this.$store.state.SAMCOTEC.r_path,post);
            this.card = response.data.results.data;
            this.terms = response.data.results.terms;
            for(let i=0;i<this.card._cars.length;i++){
                this.currentCars.push({
                    text:this.card._cars[i].plate_number,value:this.card._cars[i].plate_number
                })
                if(i==0){
                    this.newrow.plate_number = this.card._cars[i].plate_number
                }
            }
        },
        async getStatic(){
            const post = new FormData();
            post.append("type","getStaticOptions");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append("data[phrase]","")
            const repsponse = await axios.post(this.$store.state.SAMCOTEC.r_path,post)
            for(let i=0;i<repsponse.data.results.data.length;i++){
                if(repsponse.data.results.data[i].option_phrase == "vattypes"){
                    this.vattypes.push({
                        value: repsponse.data.results.data[i].value,
                        text: (this.lang.langname == "ar") ? repsponse.data.results.data[i].name_ar : repsponse.data.results.data[i].name_en
                    })
                }
                if(repsponse.data.results.data[i].option_phrase == "rowType"){
                    this.rowType.push({
                        value: repsponse.data.results.data[i].value,
                        text: (this.lang.langname == "ar") ? repsponse.data.results.data[i].name_ar : repsponse.data.results.data[i].name_en
                    })
                }
            }
            
        },
        printPDF (id,t)
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            if(t == 1)
                pdfFrame.src = '../api/print.php?invid='+id;
            if(t == 2)
                pdfFrame.src = '../api/print.php?recpid='+id;
            if(t == 3)
                pdfFrame.src = '../api/print.php?newcard='+id;
        },
    },
}
</script>
<style>
.font-size-14{
  font-size:14px;
}
.mybtn{
  width:100%;
  font-size:.8rem;
  border:0px solid #fff;
}
.mybtn2{
  width:100%;
  font-size:.8rem;
  border:0px solid #fff;
}
.mybtn4{
  width:100%;
  font-size:.8rem;
  background:rgb(15, 90, 230) !important;
  border:0px solid #fff;
}
.mybtn3{
  width:100%;
  font-size:.8rem;
  background:#198754 !important;
  border:0px solid #fff;
}
.mybtn1{
  width:100%;
  font-size:.8rem;
  background:#fe7b33 !important;
  border:0px solid #fff;
}
.v-application .elevation-1{
 box-shadow: none !important;
}
</style>