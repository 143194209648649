<template>
    <div>
        <b-sidebar backdrop id="invoiceApprove" width="50rem" aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" right  shadow >
            <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>تاكيد الفاتورة</span>
                </div>
                <div @click="hide" id="CloseIetss" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                    <span>اغلاق</span>
                </div>
            </div>
            <v-divider></v-divider>
            <div class="px-3 py-2" style="direction:rtl">
                <v-row>
                    <v-col cols="12">
                        <br />
                        <br />
                        <br />
                        <label>هل انت متاكد من ان البيانات التالية صحيحة؟</label>
                        <v-simple-table>
                            <thead>
                                <th>الاجمالي بدون ضريبة</th>
                                <th>الضريبة 15%</th>
                                <th>الاجمالي مع الضريبة</th>
                                <th>المبلغ المدفوع</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{ total }}</td>
                                    <td>{{ vat }}</td>
                                    <td>{{ ftotal }}</td>
                                    <td>{{ paid }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                        <br />
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th>اللوحة</th>
                                    <th>رمز الصنف</th>
                                    <th>الوصف</th>
                                    <th>الكمية</th>
                                    <th>السعر</th>
                                    <th>الاجمالي بدون ضريبة</th>
                                    <th>الضريبة</th>
                                    <th>الاجمالي مع الضريبة</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in invrow" :key="index">
                                    <td>{{ item.plate_number }}</td>
                                    <td>{{ item.itemcode }}</td>
                                    <td>{{ item.item_name }}</td>
                                    <td>{{ item.qty }}</td>
                                    <td>{{ item.item_price }}</td>
                                    <td>{{ item.total }}</td>
                                    <td>{{ item.vat }}</td>
                                    <td>{{ item.ftotal }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </div>
    </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" variant="success" @click='addUser()' class="ma-2" style="width:100px;">انا متاكد</b-button>
                <b-button type="button" variant="danger" @click='cancel()' class="ma-2" style="width:100px;">الغاء</b-button>
                </div>
            </template>
        </b-sidebar>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import {SnotifyPosition, SnotifyStyle} from 'vue-snotify';
import axios from 'axios';
export default {
    data() {
        return {
            total: '',
            vat: '',
            ftotal: '',
            paid: '',
            invrow:[],
            cardid: 0,
        }
    },
    methods: {
        modifyIt(note){
            console.log(this.$parent.$parent.tbrows);
            this.total = note.total,
            this.vat = note.vat
            this.ftotal = note.ftotal
            this.paid = note.paid
            this.invrow = this.$parent.$parent.tbrows
        },
        addUser(){
            this.$parent.$parent.addInvoice()
            document.getElementById('CloseIetss').click();
        },
        cancel(){
            document.getElementById('CloseIetss').click();
        }
    },
}
</script>